export const horsEchelons = [
  {
    "id": 1,
    "echelon": "A",
    "chevron": 1,
    "indice_majore": 890
  },
  {
    "id": 2,
    "echelon": "A",
    "chevron": 2,
    "indice_majore": 925
  },
  {
    "id": 3,
    "echelon": "A",
    "chevron": 3,
    "indice_majore": 972
  },
  {
    "id": 4,
    "echelon": "B",
    "chevron": 1,
    "indice_majore": 972
  },
  {
    "id": 5,
    "echelon": "B",
    "chevron": 2,
    "indice_majore": 1013
  },
  {
    "id": 6,
    "echelon": "B",
    "chevron": 3,
    "indice_majore": 1067
  },
  {
    "id": 7,
    "echelon": "BB",
    "chevron": 1,
    "indice_majore": 1067
  },
  {
    "id": 8,
    "echelon": "BB",
    "chevron": 2,
    "indice_majore": 1095
  },
  {
    "id": 9,
    "echelon": "BB",
    "chevron": 3,
    "indice_majore": 1124
  },
  {
    "id": 10,
    "echelon": "C",
    "chevron": 1,
    "indice_majore": 1124
  },
  {
    "id": 11,
    "echelon": "C",
    "chevron": 2,
    "indice_majore": 1148
  },
  {
    "id": 12,
    "echelon": "C",
    "chevron": 3,
    "indice_majore": 1173
  },
  {
    "id": 13,
    "echelon": "D",
    "chevron": 1,
    "indice_majore": 1173
  },
  {
    "id": 14,
    "echelon": "D",
    "chevron": 2,
    "indice_majore": 1226
  },
  {
    "id": 15,
    "echelon": "D",
    "chevron": 3,
    "indice_majore": 1279
  },
  {
    "id": 16,
    "echelon": "E",
    "chevron": 1,
    "indice_majore": 1279
  },
  {
    "id": 17,
    "echelon": "E",
    "chevron": 2,
    "indice_majore": 1329
  },
  {
    "id": 18,
    "echelon": "F",
    "chevron": 1,
    "indice_majore": 1378
  },
  {
    "id": 19,
    "echelon": "G",
    "chevron": 1,
    "indice_majore": 1510
  }
];
