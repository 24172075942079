import React from "react";

const Footer = () => {
  return (
    <footer className="flex justify-center items-center bg-gray-200">
      <div className="flex footer w-3/4">
        <div className="cisirh_logo m-4 flex flex-col justify-center items-center">
          <p>Développé par:</p>
          <a href="https://www.economie.gouv.fr/cisirh">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_cisirh.png`}
              alt="Acceuil"
              className="img_logo"
            />
          </a>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
