import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TableEchelons } from "./TableEchelons";

import { Button } from "@mui/material";
import {
  COULEUR_INDEMNITE_RESIDENCE,
  COULEUR_SALAIRE,
  COULEUR_SFT,
} from "../config";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as Tooltip_chartjs,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { EchelonsType, GradesType, VillesType } from "../services/dataType";
import { calculDetailSalaire, calculSFT } from "../services/utils";
import { ArrowForward, ArrowBack } from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip_chartjs,
  Legend
);

interface simCarPropsTypes {
  echelons: EchelonsType[];
  choixEchelons: string;
  userGrade: GradesType | undefined;
  userVille: VillesType | undefined;
  nbEnfants: number;
  tolliniExist: boolean;
}

const SimCar = (props: simCarPropsTypes) => {
  const navigate = useNavigate();
  const chartRef = useRef();

  // Recuperation d'un tableau contenant les indices majores selon l'echelon
  let listeIndiceMajore = props.echelons.map((e) => {
    return Number(e.indice_majore);
  });

  // salaire = indice majore * point d'indice + indemnite residence + supplement familial
  let detailSalaire = calculDetailSalaire(
    listeIndiceMajore,
    props.userVille,
    props.nbEnfants
  );

  // React Chart Js Setup
  let labels = props.echelons.map((e) => {
    return e.libelle;
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Salaire de base",
        data: detailSalaire.listeSalaireBase,
        backgroundColor: COULEUR_SALAIRE,
        stack: "Stack 0",
      },
      {
        label: "Indemnité résidence",
        data: detailSalaire.listeIndemniteResidence,
        backgroundColor: COULEUR_INDEMNITE_RESIDENCE,
        stack: "Stack 0",
      },
      {
        label: "Supplément familial de traitement",
        data: detailSalaire.listeSupplementFamilial,
        backgroundColor: COULEUR_SFT,
        stack: "Stack 0",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        display: true,
        text: props.userGrade?.libelle,
        font: {
          size: 24,
        },
        color: "black",
      },
      legend: {
        labels: {
          font: {
            size: 14,
          },
        },
      },
    },
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  console.log(calculSFT(461, 4));

  return (
    <div className="grid grid-cols-12 h-full my-4 ">
      <div className="col-span-1 flex justify-center items-center">
        <Button
          className="w-full h-full flex flex-col mx-auto"
          onClick={() => navigate("/simulateur-carriere", { replace: false })}
        >
          <ArrowBack fontSize="large" />
          <span>Revenir au formulaire</span>
        </Button>
      </div>

      <div className="col-span-10 w-10/12 m-auto flex justify-center items-center flex-col">
        <div className="wrapper-bar-chart w-10/12 responsive-hide">
          <Bar id="bar-chart" options={options} data={data} ref={chartRef} />
        </div>

        <TableEchelons
          echelons={props.echelons}
          choixEchelons={props.choixEchelons}
          detailSalaire={detailSalaire}
          libelleGrade={props.userGrade.libelle || ""}
        />

        {/* <div className="export">
          <Button onClick={exportChart}>Exporter le graphique</Button>
        </div> */}
      </div>

      <div className="col-span-1">
        {props.tolliniExist && (
          <Button
            className="w-full h-full flex flex-col mx-auto"
            onClick={() => navigate("/simulateur-carriere/evolution-carriere", { replace: false })}
          >
            <ArrowForward fontSize="large" />
            <span>Évolution de carrière</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export { SimCar };
