import axios, { AxiosInstance } from "axios";
import { API_URL } from "../config";
import {
  EchelonsType,
  GradesType,
  MinisteresType,
  VillesType,
  DepartementsType,
  isTolliniType,
} from "./dataType";

import { getIndiceFromLetter } from "./utils";
class API_generator {
  ApiInstance: AxiosInstance;

  constructor() {
    this.ApiInstance = axios.create({
      baseURL: API_URL,
    });
  }

  getAxiosInstance() {
    return this.ApiInstance
  }

  getMinisteres() {
    return this.ApiInstance.get<MinisteresType[]>("/ministeres").then(
      (res) => res.data
    );
  }

  getGrades(code_ministere: string) {
    return this.ApiInstance.get<GradesType[]>(`/grades/${code_ministere}`).then(
      (res) => {
        // console.log(res.data)
        return res.data.sort((a, b) =>(a.libelle > b.libelle) ? 1 : -1)
      }
    );
  }

  getEchelons(code_grade: string) {
    return this.ApiInstance.get<EchelonsType[]>(`/echelons/${code_grade}`).then(
      (res) => {
        let result = res.data;
        // cas indice majore hors echelon
        result.forEach((r) => {
          r.indice_majore = getIndiceFromLetter(r.indice_majore);
        });
        result = result.sort(
          (a, b) => Number(a.indice_majore) - Number(b.indice_majore)
        );
        return result;
      }
    );
  }

  getDepartements() {
    return this.ApiInstance.get<DepartementsType[]>("/departements").then(
      (res) => {
        return res.data;
      }
    );
  }

  getVilles(idDepartement: string) {
    return this.ApiInstance.get<VillesType[]>(`/villes/${idDepartement}`).then(
      (res) => {
        // console.log(res.data)
        return res.data;
      }
    );
  }

  getTollini(idGrades: string) {
    return this.ApiInstance.get<isTolliniType>(`/tollini/exist/${idGrades}`).then(
      (res) => {
        let result = res.data;
        return result.isTollini;
      }
    );
  }
}

export default new API_generator();
