import {
  INDEMNITE_RESIDENCE_ZONE_1,
  INDEMNITE_RESIDENCE_ZONE_2,
  INDEMNITE_RESIDENCE_ZONE_3,
  MAX_MENSUEL_1E_ENFANT,
  MAX_MENSUEL_2E_ENFANT,
  MAX_MENSUEL_3E_ENFANT,
  MAX_MENSUEL_ENFANT_SUPP,
  MIN_INDEMNITE_RESIDENCE_ZONE_1,
  MIN_INDEMNITE_RESIDENCE_ZONE_2,
  MIN_INDEMNITE_RESIDENCE_ZONE_3,
  MIN_MENSUEL_1E_ENFANT,
  MIN_MENSUEL_2E_ENFANT,
  MIN_MENSUEL_3E_ENFANT,
  MIN_MENSUEL_ENFANT_SUPP,
  PART_FIXE_1E_ENFANT,
  PART_FIXE_2E_ENFANT,
  PART_FIXE_3E_ENFANT,
  PART_FIXE_ENFANT_SUPP,
  POINT_D_INDICE,
  POURCENT_BRUT_1_ENFANT,
  POURCENT_BRUT_2_ENFANT,
  POURCENT_BRUT_3_ENFANT,
  POURCENT_BRUT_ENFANT_SUPP,
} from "../config";
import { detailSalaireType, EchelonsType, VillesType } from "./dataType";
import { horsEchelons } from "./staticData";

// Calcul global (salaire selon l'indice majore, indemnite residence, sft)
export function calculDetailSalaire(
  listeIndices: number[],
  ville: VillesType | undefined,
  nbEnfants: number
): detailSalaireType {
  let detailSalaire: detailSalaireType = {
    listeSalaireTotal: [],
    listeSalaireBase: [],
    listeIndemniteResidence: [],
    listeSupplementFamilial: [],
  };

  for (let i = 0; i < listeIndices.length; i++) {
    detailSalaire.listeSalaireBase.push(listeIndices[i] * POINT_D_INDICE);
    if (typeof ville != "undefined") {
      detailSalaire.listeIndemniteResidence.push(
        calculIndemniteResidence(detailSalaire.listeSalaireBase[i], ville.zone)
      );
    }

    detailSalaire.listeSupplementFamilial.push(
      calculSFT(listeIndices[i], nbEnfants)
    );

    detailSalaire.listeSalaireTotal.push(
      detailSalaire.listeSalaireBase[i] +
        detailSalaire.listeIndemniteResidence[i] +
        detailSalaire.listeSupplementFamilial[i]
    );
  }

  return detailSalaire;
}

// Calcul de la prime pour les enfants
export function calculSFT(indice_majore: number, nbEnfants: number) {
  let salaireBase = indice_majore * POINT_D_INDICE;
  let total = 0;
  if (nbEnfants === 0) return total;

  // UN ENFANT
  if (nbEnfants === 1) {
    let to_add = (POURCENT_BRUT_1_ENFANT * salaireBase) / 100;
    to_add += PART_FIXE_1E_ENFANT;

    if (to_add > MAX_MENSUEL_1E_ENFANT - PART_FIXE_1E_ENFANT)
      to_add = MAX_MENSUEL_1E_ENFANT;

    if (to_add < MIN_MENSUEL_1E_ENFANT - PART_FIXE_1E_ENFANT)
      to_add = MIN_MENSUEL_1E_ENFANT;

    total += to_add;

    return total;
  }

  // DEUX ENFANT
  if (nbEnfants === 2) {
    let to_add = (POURCENT_BRUT_2_ENFANT * salaireBase) / 100;
    to_add += PART_FIXE_2E_ENFANT;

    if (to_add > MAX_MENSUEL_2E_ENFANT - PART_FIXE_2E_ENFANT)
      to_add = MAX_MENSUEL_2E_ENFANT;

    if (to_add < MIN_MENSUEL_2E_ENFANT - PART_FIXE_2E_ENFANT)
      to_add = MIN_MENSUEL_2E_ENFANT;

    total += to_add;
    return total;
  }

  // TROISIEME ENFANT
  if (nbEnfants >= 3) {
    let to_add = (POURCENT_BRUT_3_ENFANT * salaireBase) / 100;
    to_add += PART_FIXE_3E_ENFANT;

    if (to_add > MAX_MENSUEL_3E_ENFANT - PART_FIXE_3E_ENFANT)
      to_add = MAX_MENSUEL_3E_ENFANT;

    if (to_add < MIN_MENSUEL_3E_ENFANT - PART_FIXE_3E_ENFANT)
      to_add = MIN_MENSUEL_3E_ENFANT;

    total += to_add;
  }

  // ENFANT SUPPLEMENTAIRE
  if (nbEnfants >= 4) {
    for (let i = 4; i <= nbEnfants; i++) {
      let to_add = (POURCENT_BRUT_ENFANT_SUPP * salaireBase) / 100;
      to_add += PART_FIXE_ENFANT_SUPP;

      if (to_add > MAX_MENSUEL_ENFANT_SUPP - PART_FIXE_ENFANT_SUPP)
        to_add = MAX_MENSUEL_ENFANT_SUPP;
      if (to_add < MIN_MENSUEL_ENFANT_SUPP - PART_FIXE_ENFANT_SUPP)
        to_add = MIN_MENSUEL_ENFANT_SUPP;

      total += to_add;
    }
  }

  return total;
}

// Indemnité de résidence dans la fonction publique
export function calculIndemniteResidence(salaireBase: number, zone: string) {
  let indemnite = 0;
  if (zone === "1") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_1;
  }
  if (zone === "2") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_2;
  }
  if (zone === "3") {
    indemnite = INDEMNITE_RESIDENCE_ZONE_3;
  }
  let res = salaireBase * (indemnite / 100);

  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_1 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_1
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_1;
  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_2 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_2
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_2;
  if (
    indemnite === INDEMNITE_RESIDENCE_ZONE_3 &&
    res < MIN_INDEMNITE_RESIDENCE_ZONE_3
  )
    return MIN_INDEMNITE_RESIDENCE_ZONE_3;

  return res;
}

export function getIndiceFromLetter(indice: string): string {
  if (isNaN(Number(indice))) {
    for (let i = 0; i < horsEchelons.length; i++) {
      if (horsEchelons[i].echelon === indice && horsEchelons[i].chevron === 1)
        return String(horsEchelons[i].indice_majore);
    }
  }
  return indice;
}

export function dataToCSV(
  echelons: EchelonsType[],
  detailSalaire: detailSalaireType
): string {
  let result =
    "libelle_echelon;duree_moyenne;salaire_base;supplement_familial_traitement;indemnite_residence;salaire_total\n";

  for (let i = 0; i < echelons.length; i++) {
    let line =
      echelons[i].libelle.replaceAll("è", "e").replaceAll("é", "e") +
      ";" +
      Number(echelons[i].duree) +
      ";" +
      Number(detailSalaire.listeSalaireBase[i]).toFixed(2) +
      ";" +
      Number(detailSalaire.listeSupplementFamilial[i]).toFixed(2) +
      ";" +
      Number(detailSalaire.listeIndemniteResidence[i]).toFixed(2) +
      ";" +
      Number(detailSalaire.listeSalaireTotal[i]).toFixed(2) +
      "\n";

    result += line;
  }
  return result;
}
