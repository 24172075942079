import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  Button,
  TextField,
} from "@mui/material";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { SimCar } from "./components/SimCar";
import API from "./services/api";
import { GradesType, VillesType } from "./services/dataType";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import { EvolutionCarriere } from "./components/EvolutionCarriere";

const PaperProps= {
  sx: {
    height: "50%",
    bgcolor: "white",
    "& .MuiMenuItem-root": {
      padding: 2,
    },
  },
}

function App() {
  // FORM STATE
  const [choixGrades, setChoixGrades] = useState("");
  const [choixMinisteres, setChoixMinisteres] = useState("");
  const [choixEchelons, setChoixEchelons] = useState("");
  const [choixDepartements, setChoixDepartements] = useState("");
  const [choixVilles, setChoixVilles] = useState("");
  const [nbEnfants, setNbEnfants] = useState(0);

  const [currGrade, SetCurrGrade] = useState<GradesType | undefined>();

  const ministeres = useQuery("ministeres", () => API.getMinisteres());
  const grades = useQuery(
    ["grades", choixMinisteres],
    (context) => API.getGrades(context.queryKey[1]),
    { enabled: !!choixMinisteres }
  );
  const echelons = useQuery(
    ["echelons", choixGrades],
    (context) => API.getEchelons(context.queryKey[1]),
    { enabled: !!choixGrades }
  );
  const departements = useQuery("departements", () => API.getDepartements());
  const villes = useQuery(
    ["villes", choixDepartements],
    (context) => API.getVilles(context.queryKey[1]),
    { enabled: !!choixDepartements }
  );
  const isTollini = useQuery(
    ["isTollini", choixGrades],
    (context) => API.getTollini(context.queryKey[1]),
    { enabled: !!choixGrades }
  );

  const getRangEchelon = () => {
    for (let i = 0; i < echelons.data.length; i++) {
      if (choixEchelons === echelons.data[i].code_echelon) return i;
    }
  };

  const changeMinisteres = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newMinisteres =
      ministeres.isSuccess &&
      ministeres.data.find((m) => m.code_ministere === eventValue);

    // obtention des grades(postes) associés au ministere
    if (newMinisteres) {
      setChoixMinisteres(newMinisteres.code_ministere);
      setChoixGrades("");
      setChoixEchelons("");
    }
  };

  const changeGrades = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newGrade =
      grades.isSuccess && grades.data.find((g) => g.code_grade === eventValue);
    if (newGrade) {
      SetCurrGrade(newGrade);
      setChoixGrades(newGrade.code_grade);
      setChoixEchelons("");
    }
  };

  const changeEchelons = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;
    const newEchelon =
      echelons.isSuccess &&
      echelons.data.find((e) => e.code_echelon === eventValue);
    if (newEchelon) {
      setChoixEchelons(newEchelon.code_echelon);
    }
  };

  const changeDepartements = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newDepartements =
      departements.isSuccess &&
      departements.data.find((d) => d.num_departement === eventValue);

    if (newDepartements) {
      setChoixDepartements(newDepartements.num_departement);
    }
  };

  const changeVilles = (
    e: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const eventValue = e.target.value;

    const newVille =
      villes.isSuccess && villes.data.find((v) => v.ville === eventValue);

    if (newVille) {
      setChoixVilles(newVille.ville);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <div className="App flex flex-col justify-between w-screen overflow-x-hidden">
      <Header />

      <div className="content h-full">
        <Routes>
          <Route path="/" element={<Navigate to="simulateur-carriere" />} />
          <Route
            path="/simulateur-carriere"
            element={
              <div className="flex justify-center items-center content-wrapper h-full w-full bg-grey">
                <div className="content flex justify-center items-center w-1/2 m-2 p-2">
                  <div className="flex-col justify-center items-center w-screen border-4 rounded-xl p-2²">
                    <h1 className="text-3xl p-4 text-center">
                      Précisez votre situation
                    </h1>
                    <form
                      onSubmit={handleFormSubmit}
                      className="flex flex-col justify-center items-center gap-3"
                    >
                      <FormControl className="w-96">
                        <InputLabel
                          id="select-ministeres"
                          className="text-center"
                        >
                          Selectionnez votre Ministère
                        </InputLabel>
                        <Select
                          name="ministeres"
                          labelId="select-ministeres"
                          onChange={changeMinisteres}
                          className="my-2 py-2 w-full"
                          value={choixMinisteres}
                        >
                          {ministeres.isSuccess &&
                            ministeres.data.map((m, index) => (
                              <MenuItem value={m.code_ministere} key={index}>
                                {m.libelle}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <FormControl className="w-96">
                        <InputLabel id="select-grades">
                          Selectionnez votre Grade
                        </InputLabel>
                        <Select
                          label="test"
                          name="grades"
                          labelId="select-grades"
                          onChange={changeGrades}
                          className="my-2 py-2 w-full"
                          value={choixGrades}
                          MenuProps={{
                            PaperProps
                          }}
                        >
                          {grades.isSuccess &&
                            grades.data.map((g, index) => (
                              <MenuItem value={g.code_grade} key={index}>
                                {g.libelle} (code grade: {g.code_grade})
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-echelons">
                          Selectionnez votre échelon
                        </InputLabel>
                        <Select
                          name="echelons"
                          labelId="select-echelons"
                          onChange={changeEchelons}
                          className="my-2 py-2 w-full"
                          value={choixEchelons}
                        >
                          {echelons.isSuccess &&
                            echelons.data.map((e, index) => (
                              <MenuItem value={e.code_echelon} key={index}>
                                {e.libelle}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-departements">
                          Selectionnez votre Département
                        </InputLabel>
                        <Select
                          name="departements"
                          labelId="select-departements"
                          onChange={changeDepartements}
                          className="my-2 py-2 w-full"
                          value={choixDepartements}
                        >
                          {departements.isSuccess &&
                            departements.data.map((d, index) => (
                              <MenuItem value={d.num_departement} key={index}>
                                {d.libelle}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <InputLabel id="select-villes">
                          Selectionnez votre Ville
                        </InputLabel>
                        <Select
                          name="villes"
                          labelId="select-villes"
                          onChange={changeVilles}
                          className="my-2 py-2 w-full"
                          value={choixVilles}
                        >
                          {villes.isSuccess &&
                            villes.data.map((v, index) => (
                              <MenuItem
                                value={v.ville}
                                key={index}
                                defaultValue=""
                              >
                                {v.ville}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                      <FormControl className="w-96">
                        <TextField
                          onChange={(e) => setNbEnfants(Number(e.target.value))}
                          className="my-2 py-2"
                          value={nbEnfants}
                          type="number"
                          label="Nombre d'enfant(s) à charge"
                          id="input-nbEnfants"
                        />
                      </FormControl>
                      <FormControl className="flex justify-center items-center">
                        <Link to="/simulateur-carriere/chart">
                          <div className="m-4">
                            <Button type="submit" variant="contained">
                              Lancer le simulateur de carrière
                            </Button>
                          </div>
                        </Link>
                      </FormControl>
                    </form>
                  </div>
                </div>
              </div>
            }
          />

          {/* Chart Simulateur de Carrière */}
          <Route
            path="simulateur-carriere/chart"
            element={
              <SimCar
                echelons={echelons.isSuccess ? echelons.data : []}
                choixEchelons={choixEchelons}
                userGrade={
                  grades.isSuccess
                    ? grades.data.find((g) => choixGrades === g.code_grade)
                    : ({} as GradesType)
                }
                userVille={
                  villes.isSuccess
                    ? villes.data.find((v) => choixVilles === v.ville)
                    : ({} as VillesType)
                }
                nbEnfants={nbEnfants}
                tolliniExist={isTollini.isSuccess && isTollini.data}
              />
            }
          />

          {/* {isTollini.isSuccess && isTollini.data && (
            <Route
              path="simulateur-carriere/evolution-carriere"
              element={
                echelons.isSuccess && (
                  <EvolutionCarriere
                    echelons={echelons.data}
                    rangEchelon={getRangEchelon()}
                    codeGrade={choixGrades}
                    currentCodeEchelon={choixEchelons}
                  ></EvolutionCarriere>
                )
              }
            ></Route>
          )} */}
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
