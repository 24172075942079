export const API_URL = "/api/simulateur-carriere";
export const POINT_D_INDICE: number = 4.686025;
// INDEMNITE RESIDENCE
// https://www.service-public.fr/particuliers/vosdroits/F32511
export const MIN_INDEMNITE_RESIDENCE_ZONE_1: number = 49.48;
export const MIN_INDEMNITE_RESIDENCE_ZONE_2: number = 16.49;
export const MIN_INDEMNITE_RESIDENCE_ZONE_3: number = 0;
export const INDEMNITE_RESIDENCE_ZONE_1: number = 0
export const INDEMNITE_RESIDENCE_ZONE_2: number = 1
export const INDEMNITE_RESIDENCE_ZONE_3: number = 3

// SUPPLEMENT FAMILIAL
// https://www.service-public.fr/particuliers/vosdroits/F32513
export const PART_FIXE_1E_ENFANT: number = 2.29;
export const PART_FIXE_2E_ENFANT: number = 10.67;
export const PART_FIXE_3E_ENFANT: number = 15.24;
export const PART_FIXE_ENFANT_SUPP: number = 4.57;

export const POURCENT_BRUT_1_ENFANT: number = 0;
export const POURCENT_BRUT_2_ENFANT: number = 3;
export const POURCENT_BRUT_3_ENFANT: number = 8;
export const POURCENT_BRUT_ENFANT_SUPP: number = 6;

export const MIN_MENSUEL_1E_ENFANT: number = 2.29;
export const MIN_MENSUEL_2E_ENFANT: number = 73.79;
export const MIN_MENSUEL_3E_ENFANT: number = 183.56;
export const MIN_MENSUEL_ENFANT_SUPP: number = 130.81;

export const MAX_MENSUEL_1E_ENFANT: number = 2.29;
export const MAX_MENSUEL_2E_ENFANT: number = 111.47;
export const MAX_MENSUEL_3E_ENFANT: number = 284.03;
export const MAX_MENSUEL_ENFANT_SUPP: number = 206.17;

// COULEUR BARCHART
export const COULEUR_SALAIRE: string = '#223b89';
export const COULEUR_INDEMNITE_RESIDENCE: string = '#d87d0f';
export const COULEUR_SFT: string = '#e20e21';

// Evolution du carrière (arbre) couleur avec la syntaxe de tailwind
export const TREE_MAX_DEPTH = 3;
export const COULEUR_GRADE_ACTUELLE: string = 'bg-blue-100';
export const COULEUR_GRADE_SUIVANT: string = 'bg-gray-100';

