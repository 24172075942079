const Header = () => {
  return (
    <header className="w-full shadow-lg bg-white flex justify-center">
      <div className="flex justify-center items-center">
        <div id="w-full">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/assets/logo_ministere_economie.png`}
              alt="Acceuil"
              className="m-4"
              id="logo"
            />
          </a>
        </div>

        <div className="title flex flex-col justify-center mx-8">
          <a href="/">
            <h1 className="text-bleue_titre font-medium text-3xl">
              Simulateur de Carrière
            </h1>
          </a>

          <p className="gray-800">
            Centre interministériel de Service Informatiques relatifs aux
            Ressources Humaines
          </p>
        </div>
      </div>
    </header>
  );
};

export { Header };
